import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Bottombar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/CTA/Spinner";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  todos,
  reload,
  setReload,
  companyData,
  isLoading,
  setIsLoading,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  const [actualTodo, setActualTodo] = useState({});
  const [othersTodo, setOthersTodo] = useState({});

  useEffect(() => {
    let noTodoReza = true;
    let noTodoMasih = true;
    if (!isEmpty(todos) && !isEmpty(companyData)) {
      let masihTodo;
      let rezaTodo;
      todos.map((todo) => {
        if (isEmpty(todo.end_unix) && todo.company_id == "114") {
          rezaTodo = todo;
          noTodoReza = false;
        }
        if (isEmpty(todo.end_unix) && todo.company_id == "96") {
          masihTodo = todo;
          noTodoMasih = false;
        }
      });

      if (companyData.id == "114") {
        setActualTodo(rezaTodo);
        setOthersTodo(masihTodo);
      } else {
        setActualTodo(masihTodo);
        setOthersTodo(rezaTodo);
      }
      if (noTodoMasih && companyData.id == "96") {
        navigate("/newTodo");
      }
      if (noTodoReza && companyData.id == "114") {
        navigate("/newTodo");
      }
    }
  }, [todos, companyData]);

  const endTask = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let nowDate = Math.floor(Date.now() / 1000);

    const resa = await fetch(
      `${apiURL}/api/todos/index.php?id=` + actualTodo.id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          end_unix: nowDate,
        }),
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = () => {
    setReload(!reload);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  function timeSince(unixTimestamp) {
    // Get the current time in milliseconds
    const now = Date.now();
    // Convert the Unix timestamp from seconds to milliseconds
    const past = unixTimestamp * 1000;
    // Calculate the difference in milliseconds
    const diff = now - past;

    // Calculate the time difference in units
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    // Generate the formatted string
    let result = "Seit ";

    if (days > 0) {
      result += `${days} Tagen `;
      // Calculate the remaining hours after subtracting days
      const remainingHours = hours % 24;
      if (remainingHours > 0) {
        result += `und ${remainingHours} Stunden `;
      }
    } else if (hours > 0) {
      result += `${hours} Stunden `;
    } else if (minutes > 0) {
      result += `${minutes} Minuten `;
    } else {
      result += `${seconds} Sekunden `;
    }

    result += "dabei";

    return result.trim();
  }

  return (
    <div className="home">
      <div className="home_title">
        Erledige Dinge schnell. Du hast keine Zeit mehr.
      </div>
      <div className="home_card">
        <div className="home_card_top">
          <img
            src="https://cloud.erledigt.net/api/images/placeholder/reza.jpeg"
            className="home_card_top_img"
          />
          <div className="home_card_top_name">Reza</div>
        </div>
        <div className="home_card_bottom">
          <div className="home_card_bottom_task">
            Nächster Schritt:{" "}
            {!isEmpty(companyData) &&
              companyData.id == "96" &&
              !isEmpty(othersTodo) &&
              othersTodo.todo_text}
            {!isEmpty(companyData) &&
              companyData.id == "114" &&
              !isEmpty(actualTodo) &&
              actualTodo.todo_text}
          </div>
          <div className="home_card_bottom_task_started">
            {!isEmpty(companyData) &&
              companyData.id == "96" &&
              !isEmpty(othersTodo) &&
              timeSince(othersTodo.start_unix)}
            {!isEmpty(companyData) &&
              companyData.id == "114" &&
              !isEmpty(actualTodo) &&
              timeSince(actualTodo.start_unix)}
          </div>
          {!isEmpty(companyData) && companyData.id == "114" && (
            <>
              {!isLoading ? (
                <div
                  className="home_card_bottom_task_endbutton button"
                  onClick={() => endTask()}>
                  Erledigt
                </div>
              ) : (
                <div className="home_card_bottom_task_endbutton button">
                  <Spinner type="white" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="home_card">
        <div className="home_card_top">
          <img
            src="https://cloud.erledigt.net/api/images/placeholder/masih.jpeg"
            className="home_card_top_img"
          />
          <div className="home_card_top_name">Masih</div>
        </div>
        <div className="home_card_bottom">
          <div className="home_card_bottom_task">
            Nächster Schritt:{" "}
            {!isEmpty(companyData) &&
              companyData.id == "114" &&
              !isEmpty(othersTodo) &&
              othersTodo.todo_text}
            {!isEmpty(companyData) &&
              companyData.id == "96" &&
              !isEmpty(actualTodo) &&
              actualTodo.todo_text}
          </div>
          <div className="home_card_bottom_task_started">
            {!isEmpty(companyData) &&
              companyData.id == "114" &&
              !isEmpty(othersTodo) &&
              timeSince(othersTodo.start_unix)}
            {!isEmpty(companyData) &&
              companyData.id == "96" &&
              !isEmpty(actualTodo) &&
              timeSince(actualTodo.start_unix)}
          </div>
          {!isEmpty(companyData) && companyData.id == "96" && (
            <>
              {!isLoading ? (
                <div
                  className="home_card_bottom_task_endbutton button"
                  onClick={() => endTask()}>
                  Erledigt
                </div>
              ) : (
                <div className="home_card_bottom_task_endbutton button">
                  <Spinner type="white" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
