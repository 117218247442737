import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Bottombar.css";
import { useLocation, useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import AutoresizeTextarea from "../components/AutoresizeTextarea";
import Spinner from "../components/CTA/Spinner";

export default function NewTodo({
  isMobile,
  apiURL,
  authToken,
  todos,
  reload,
  setReload,
  companyData,
  isLoading,
  setIsLoading,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;

  const [newTodoText, setNewTodoText] = useState("");

  const handleUploadNewTodo = async () => {
    // post end_unix by id
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    let nowDate = Math.floor(Date.now() / 1000);

    const resa = await fetch(`${apiURL}/api/todos/index.php`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        company_id: companyData.id,
        todo_text: newTodoText,
        start_unix: nowDate,
      }),
    });

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = () => {
    setIsLoading(false);
    setReload(!reload);
  };

  useEffect(() => {
    let noTodoReza = true;
    let noTodoMasih = true;
    if (!isEmpty(todos) && !isEmpty(companyData)) {
      let masihTodo;
      let rezaTodo;
      todos.map((todo) => {
        if (isEmpty(todo.end_unix) && todo.company_id == "114") {
          rezaTodo = todo;
          noTodoReza = false;
        }
        if (isEmpty(todo.end_unix) && todo.company_id == "96") {
          masihTodo = todo;
          noTodoMasih = false;
        }
      });
      if (!noTodoMasih && companyData.id == "96") {
        navigate("/");
      }
      if (!noTodoReza && companyData.id == "114") {
        navigate("/");
      }
    }
  }, [todos, companyData]);

  useEffect(() => {
    if (isEmpty(authToken)) {
      navigate("/login");
    }
  }, [authToken]);

  return (
    <div className="home">
      <div className="home_title">Was ist dein nächster Schritt</div>
      <div className="home_newTodo_textbox">
        <AutoresizeTextarea
          text={newTodoText}
          setText={setNewTodoText}
          classname={"home_newTodo_textbox_input"}
        />
      </div>
      {!isLoading ? (
        <div
          className="home_newTodo_upload button"
          onClick={() => handleUploadNewTodo()}>
          Starten
        </div>
      ) : (
        <div className="home_newTodo_upload button">
          <Spinner type="white" />
        </div>
      )}
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
